
@use "sass:math";

:host {
	bs-daterangepicker-inline {
		::ng-deep {
			.bs-datepicker {
				box-shadow: none;
				background: none;
				.bs-datepicker-container {
					padding: .125rem;
					.bs-datepicker-head {
						button {
							&.current {
								padding: 0;
							}
						}
					}
					.bs-datepicker-body {
						border: .0625rem solid rgba(255, 255, 255, 0.07);
						table {
							th {
								color: $white;
							}
							td {
								color: $grey;
								&:first-child {
									span {
										&:before {
											left: -0.125rem;
										}
									}
								}
								&:last-child {
									span {
										&:before {
											right: -0.125rem;
										}
									}
								}
							}
							td[class*="select-"] span:after {
								background-color: $primary !important;
							}
							td span.selected {
								background-color: $primary !important;
							}
							td.disabled span {
								color: rgba(255, 255, 255, 0.25);
							}
							span {
								&.is-other-month {
									color: rgba(255, 255, 255, 0.55);
								}
								&:before {
									left: -0.875rem;
									right: -0.875rem;
								}
								&.in-range:not(.select-start):not(.select-end):before {
									background: $primary;
								}
								&.in-range:hover:before {
									background: transparent !important;
								}
								&.is-highlighted:not(.disabled):not(.selected) {
									background: $primary !important;
								}
							}
						}
					}

					.bs-datepicker-multiple {
						width: math.div($dialog-width , 2 - 10);
					}
				}
			}
      bs-datepicker-navigation-view {
        font-size: 1.25rem !important;
      }
		}
	}
}