@import "./mixins.scss";
@import "./themes/dark.scss";
@import "./themes/default.scss";

:root {
  --tiny              : 0.6rem;
  --small             : 0.8rem;
  --normal            : 1rem;
  --big               : 1.4rem;
  --bigger            : 2rem;
  --huge              : 4rem;
  --base-font-size    : 16px;
  --t-fastoutslowin   : cubic-bezier(0.4, 0, 0.2, 1);
  --ctrl-padding      : 1rem;
  --ctrl-height       : 2.5rem;
  --ctrl-border-radius: 0.3rem;
  --ctrl-border-width : 1px;
}
body {
  background-color: $color-background;
  color: $color-text;
  font-size: $base-font-size
}

.dark-label {
  color: $color-background !important;
}

/* Background colors (important is because of bootstrap :'( ) */
.bg-background {
  background-color: $color-background !important;
}
.bg-primary {
  background-color: $color-primary !important;
}
.bg-primaryVariant {
  background-color: $color-primaryVariant !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}
.bg-secondaryVariant {
  background-color: $color-secondaryVariant !important;
}
.bg-success {
  background-color: $color-success !important;
}
.bg-info {
  background-color: $color-info !important;
}
.bg-warning {
  background-color: $color-warning !important;
}
.bg-danger {
  background-color: $color-danger !important;
}
.bg-text {
  background-color: $color-text !important;
}
.bg-grey {
  background-color: $color-grey !important;
}
.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

/* Background colors hover */
.hover-bg-background:hover {
  background-color: $color-background !important;
}
.hover-bg-primary:hover {
  background-color: $color-primary !important;
}
.hover-bg-primaryVariant:hover {
  background-color: $color-primaryVariant !important;
}
.hover-bg-secondary:hover {
  background-color: $color-secondary !important;
}
.hover-bg-secondaryVariant:hover {
  background-color: $color-secondaryVariant !important;
}
.hover-bg-success:hover {
  background-color: $color-success !important;
}
.hover-bg-info:hover {
  background-color: $color-info !important;
}
.hover-bg-warning:hover {
  background-color: $color-warning !important;
}
.hover-bg-danger:hover {
  background-color: $color-danger !important;
}
.hover-bg-text:hover {
  background-color: $color-text !important;
}
.hover-bg-grey:hover {
  background-color: $color-grey !important;
}
.hover-bg-transparent:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Border colors */
.border-background {
  border-color: $color-background !important;
}
.border-primary {
  border-color: $color-primary !important;
}
.border-primaryVariant {
  border-color: $color-primaryVariant !important;
}
.border-secondary {
  border-color: $color-secondary !important;
}
.border-secondaryVariant {
  border-color: $color-secondaryVariant !important;
}
.border-success {
  border-color: $color-success !important;
}
.border-info {
  border-color: $color-info !important;
}
.border-warning {
  border-color: $color-warning !important;
}
.border-danger {
  border-color: $color-danger !important;
}
.border-text {
  border-color: $color-text !important;
}
.border-grey {
  border-color: $color-grey !important;
}
.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}

/* Border colors hover */
.hover-border-background:hover {
  border-color: $color-background !important;
}
.hover-border-primary:hover {
  border-color: $color-primary !important;
}
.hover-border-primaryVariant:hover {
  border-color: $color-primaryVariant !important;
}
.hover-border-secondary:hover {
  border-color: $color-secondary !important;
}
.hover-border-secondaryVariant:hover {
  border-color: $color-secondaryVariant !important;
}
.hover-border-success:hover {
  border-color: $color-success !important;
}
.hover-border-info:hover {
  border-color: $color-info !important;
}
.hover-border-warning:hover {
  border-color: $color-warning !important;
}
.hover-border-danger:hover {
  border-color: $color-danger !important;
}
.hover-border-text:hover {
  border-color: $color-text !important;
}
.hover-border-grey:hover {
  border-color: $color-grey !important;
}
.hover-border-transparent:hover {
  border-color: rgba(255, 255, 255, 0.05) !important;
}

/* Text colors */
.text-background {
  color: $color-background !important;
}
.text-primary {
  color: $color-primary !important;
}
.text-primaryVariant {
  color: $color-primaryVariant !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.text-secondaryVariant {
  color: $color-secondaryVariant !important;
}
.text-success {
  color: $color-success !important;
}
.text-info {
  color: $color-info !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-text {
  color: $color-text !important;
}
.text-grey {
  color: $color-grey !important;
}
.text-transparent {
  color: rgba(0, 0, 0, 0) !important;
}
