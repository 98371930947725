@mixin darkTheme {
    --vu-color-background      : #222222;
    --vu-color-primary         : #007fff;
    --vu-color-primaryVariant  : #005dbb;
    --vu-color-secondary       : #03DAC6;
    --vu-color-secondaryVariant: #018786;
    --vu-color-success         : #8BC34A;
    --vu-color-info            : #00bcd4;
    --vu-color-warning         : #F5A623;
    --vu-color-danger          : #FF003C;
    --vu-color-text            : #ffffff;
    --vu-color-grey            : #555555;
    --vu-color-border          : #333333;
    --vu-color-grey-d2         : #3b3b3b;
    
    --vu-color-transparent-1   : rgba(255,255,255, 0.04); 
    --vu-color-transparent-2   : rgba(255,255,255, 0.07);
    --vu-color-transparent-3   : rgba(255,255,255, 0.16);
    --vu-color-transparent-4   : rgba(255,255,255, 0.4);

    --box-shadow-1          : 2px 2px 2px  0 rgba(0, 0, 0, 0.24);
    --box-shadow-2          : 2px 2px 8px  0 rgba(0, 0, 0, 0.24);
    --box-shadow-3          : 2px 2px 12px 0 rgba(0, 0, 0, 0.24);
    --box-shadow-4          : 2px 2px 24px 0 rgba(0, 0, 0, 0.24);
    --box-shadow-5          : 2px 2px 32px 0 rgba(0, 0, 0, 0.24);
}
