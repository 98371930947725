@import "variables.scss";
@import "../app/core/components/visup-ui-components/core/style/main.scss";
@import "../app/core/components/visup-ui-components/core/style/mixins.scss";

$underlineColor: #444444;

:root {
  @include darkTheme();
  --vu-color-primary: #{$primary};
  --vu-color-primaryVariant: #{$white};
  --vu-color-secondary: #{$light-blue};
  --vu-color-grey: #{$tundora};
}

.theme-light {
  @include defaultTheme();
}
visup-button-outline {
  button {
    text-transform: uppercase;
    &.border-danger {
      color: var(--vu-color-danger) !important;
      &:hover {
        color: var(--vu-color-background) !important;
      }
    }
    &.disabled {
      opacity: 0.2;
    }
  }
}

visup-select {
  visup-button-outline {
    button {
      text-transform: none !important;
    }
  }
  .select-open {
    visup-button-outline {
      &:not(.navigation--button) {
        button {
          &.round {
            border-radius: 1.3rem 1.3rem 0 0 !important;
          }
        }
      }
    }
  }
}

visup-list-ranking {
  .item-extra-value {
    font-size: 20px !important;
    padding-left: 0.25rem !important;
    padding-top: 0 !important;
  }
  .btn-container {
    display: none;
  }
  .list-item {
    margin: 15px 0;
    .item-bar-container,
    .item-bar {
      height: 8px !important;
      border-radius: 4px;
    }
    .item-bar-container {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .item-label {
      color: #fff !important;
      margin-bottom: 8px;
      font-weight: 300;
      line-height: 20px;
    }
    .item-value {
      color: #fff !important;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 2px;
    }
    .item-percentage {
      display: none;
    }
  }
  &.turquoise {
    .list-container {
      .item-bar {
        background-color: $turquoise-blue !important
      }
    }
  }
  &.portage {
    .list-container:not(.selectable) {
      .item-bar {
        background-color: $portage !important
      }
    }
  }
  &.lavender {
    .list-container:not(.selectable) {
      .item-bar {
        background-color: $lavender !important
      }
    }
  }
  &.yellow {
    .list-container:not(.selectable) {
      .item-bar {
        background-color: $light-yellow !important
      }
    }
  }
  &.green {
    .list-container:not(.selectable) {
      .item-bar {
        background-color: $success !important
      }
    }
  }
  &.turquoise,
  &.portage,
  &.lavender,
  &.yellow,
  &.green {
    &.selected-input,
    &.selected-output {
      .list-item {
        &.active {
          .item-bar {
            background-color: $pink !important
          }
        }
      }
    }
  }
  &.multicolor {
    .list-item {
      &:first-child {
        .item-bar {
          background-color: $turquoise-blue !important
        }
      }
      &:nth-child(2) {
        .item-bar {
          background-color: $portage !important
        }
      }
      &:nth-child(3) {
        .item-bar {
          background-color: $lavender !important
        }
      }
    }
  }
  &.expand {
    .list-container {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &.label-wrap {
    .item-label {
      white-space: initial !important;
    }
  }
}

visup-progress-chart {
  .visup-progress-chart {
    padding: 1rem 1rem 1rem 0;
    svg {
      .top-text {
        font-size: 1rem;
        fill: $white;
        font-weight: 100;
      }
      .top-text-2 {
        font-size: 1rem;
        font-weight: 600;
        fill: #FFFFFF;
        transform: translateY(0.4rem);
      }
      .bottom-text {
        font-size: 1.5rem;
        fill: $white;
        font-weight: 100;
      }
      .count-text {
        font-weight: 100;
      }
    }
  }
}