@mixin defaultTheme {
    --vu-color-background      : #FFFFFF;
    --vu-color-primary         : #6200EE;
    --vu-color-primaryVariant  : #3700B3;
    --vu-color-secondary       : #03DAC6;
    --vu-color-secondaryVariant: #018786;
    --vu-color-success         : #4caf50;
    --vu-color-info            : #00bcd4;
    --vu-color-warning         : #ff9800;
    --vu-color-danger          : #f44336;
    --vu-color-text            : #455a64;
    --vu-color-grey            : #777777;
    --vu-color-border          : #f1f1f1;
    --vu-color-grey-d2         : #3b3b3b;

    --vu-color-transparent-1   : rgba(0,0,0, 0.04); 
    --vu-color-transparent-2   : rgba(0,0,0, 0.1);
    --vu-color-transparent-3   : rgba(0,0,0, 0.2);
    --vu-color-transparent-4   : rgba(0,0,0, 0.4);
    
    --box-shadow-1          : 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    --box-shadow-2          : 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    --box-shadow-3          : 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    --box-shadow-4          : 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    --box-shadow-5          : 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
