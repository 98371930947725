@import 'dataviz.scss';

@mixin boxshadow($depth: 1) {
  @if $depth==1 {
    box-shadow: var(--box-shadow-1);
  } @else if $depth==2 {
    box-shadow: var(--box-shadow-2);
  } @else if $depth==3 {
    box-shadow: var(--box-shadow-3);
  } @else if $depth==4 {
    box-shadow: var(--box-shadow-4);
  } @else if $depth==5 {
    box-shadow: var(--box-shadow-5);
  }
}

@mixin errortext {
  font-size: 0.5rem;
  position: absolute;
  bottom: -0.8rem;
  color: $color-danger;
}

@mixin elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* colors definition */
$color-background         : var(--vu-color-background      );
$color-primary            : var(--vu-color-primary         );
$color-primaryVariant     : var(--vu-color-primaryVariant  );
$color-secondary          : var(--vu-color-secondary       );
$color-secondaryVariant   : var(--vu-color-secondaryVariant);
$color-success            : var(--vu-color-success         );
$color-info               : var(--vu-color-info            );
$color-warning            : var(--vu-color-warning         );
$color-danger             : var(--vu-color-danger          );
$color-text               : var(--vu-color-text            );
$color-grey               : var(--vu-color-grey            );
$color-border             : var(--vu-color-border          );

/* variants */
$color-border-d3          : var(--vu-color-border-d3);
$color-border-d2          : var(--vu-color-border-d2);
$color-border-d1          : var(--vu-color-border-d1);
$color-border-l1          : var(--vu-color-border-l1);
$color-border-l2          : var(--vu-color-border-l2);
$color-border-l3          : var(--vu-color-border-l3);
$color-background-d3      : var(--vu-color-background-d3);
$color-background-d2      : var(--vu-color-background-d2);
$color-background-d1      : var(--vu-color-background-d1);
$color-background-l1      : var(--vu-color-background-l1);
$color-background-l2      : var(--vu-color-background-l2);
$color-background-l3      : var(--vu-color-background-l3);
$color-primary-d3         : var(--vu-color-primary-d3);
$color-primary-d2         : var(--vu-color-primary-d2);
$color-primary-d1         : var(--vu-color-primary-d1);
$color-primary-l1         : var(--vu-color-primary-l1);
$color-primary-l2         : var(--vu-color-primary-l2);
$color-primary-l3         : var(--vu-color-primary-l3);
$color-primaryVariant-d3  : var(--vu-color-primaryVariant-d3);
$color-primaryVariant-d2  : var(--vu-color-primaryVariant-d2);
$color-primaryVariant-d1  : var(--vu-color-primaryVariant-d1);
$color-primaryVariant-l1  : var(--vu-color-primaryVariant-l1);
$color-primaryVariant-l2  : var(--vu-color-primaryVariant-l2);
$color-primaryVariant-l3  : var(--vu-color-primaryVariant-l3);
$color-secondary-d3       : var(--vu-color-secondary-d3);
$color-secondary-d2       : var(--vu-color-secondary-d2);
$color-secondary-d1       : var(--vu-color-secondary-d1);
$color-secondary-l1       : var(--vu-color-secondary-l1);
$color-secondary-l2       : var(--vu-color-secondary-l2);
$color-secondary-l3       : var(--vu-color-secondary-l3);
$color-secondaryVariant-d3: var(--vu-color-secondaryVariant-d3);
$color-secondaryVariant-d2: var(--vu-color-secondaryVariant-d2);
$color-secondaryVariant-d1: var(--vu-color-secondaryVariant-d1);
$color-secondaryVariant-l1: var(--vu-color-secondaryVariant-l1);
$color-secondaryVariant-l2: var(--vu-color-secondaryVariant-l2);
$color-secondaryVariant-l3: var(--vu-color-secondaryVariant-l3);
$color-success-d3         : var(--vu-color-success-d3);
$color-success-d2         : var(--vu-color-success-d2);
$color-success-d1         : var(--vu-color-success-d1);
$color-success-l1         : var(--vu-color-success-l1);
$color-success-l2         : var(--vu-color-success-l2);
$color-success-l3         : var(--vu-color-success-l3);
$color-info-d3            : var(--vu-color-info-d3);
$color-info-d2            : var(--vu-color-info-d2);
$color-info-d1            : var(--vu-color-info-d1);
$color-info-l1            : var(--vu-color-info-l1);
$color-info-l2            : var(--vu-color-info-l2);
$color-info-l3            : var(--vu-color-info-l3);
$color-warning-d3         : var(--vu-color-warning-d3);
$color-warning-d2         : var(--vu-color-warning-d2);
$color-warning-d1         : var(--vu-color-warning-d1);
$color-warning-l1         : var(--vu-color-warning-l1);
$color-warning-l2         : var(--vu-color-warning-l2);
$color-warning-l3         : var(--vu-color-warning-l3);
$color-danger-d3          : var(--vu-color-danger-d3);
$color-danger-d2          : var(--vu-color-danger-d2);
$color-danger-d1          : var(--vu-color-danger-d1);
$color-danger-l1          : var(--vu-color-danger-l1);
$color-danger-l2          : var(--vu-color-danger-l2);
$color-danger-l3          : var(--vu-color-danger-l3);
$color-text-d3            : var(--vu-color-text-d3);
$color-text-d2            : var(--vu-color-text-d2);
$color-text-d1            : var(--vu-color-text-d1);
$color-text-l1            : var(--vu-color-text-l1);
$color-text-l2            : var(--vu-color-text-l2);
$color-text-l3            : var(--vu-color-text-l3);
$color-grey-d3            : var(--vu-color-grey-d3);
$color-grey-d2            : var(--vu-color-grey-d2);
$color-grey-d1            : var(--vu-color-grey-d1);
$color-grey-l1            : var(--vu-color-grey-l1);
$color-grey-l2            : var(--vu-color-grey-l2);
$color-grey-l3            : var(--vu-color-grey-l3);
$color-transparent-1      : var(--vu-color-transparent-1);
$color-transparent-2      : var(--vu-color-transparent-2);
$color-transparent-3      : var(--vu-color-transparent-3);
$color-transparent-4      : var(--vu-color-transparent-4);

$box-shadow-1             : var(--box-shadow-1);
$box-shadow-2             : var(--box-shadow-2);
$box-shadow-3             : var(--box-shadow-3);
$box-shadow-4             : var(--box-shadow-4);


/* utils vars */
$tiny                     : var(--tiny);              
$small                    : var(--small);             
$normal                   : var(--normal);            
$big                      : var(--big);               
$bigger                   : var(--bigger);            
$huge                     : var(--huge);              
$base-font-size           : var(--base-font-size);    
$t-fastoutslowin          : var(--t-fastoutslowin);   
$ctrl-padding             : var(--ctrl-padding);      
$ctrl-height              : var(--ctrl-height);       
$ctrl-border-radius       : var(--ctrl-border-radius);
$ctrl-border-width        : var(--ctrl-border-width); 